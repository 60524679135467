import React, { useState } from "react";
import { TestimonialData } from '../Data/TestimonialData'
import '../Testimonials/Testimonials.css'
import leftarrow from '../images/left.png'
import rightarrow from '../images/right.png'
import Join from "./Join/Join";
import Footer from "../Footer/Footer";
import {motion} from 'framer-motion'

const Testimonials = () => {
  const transition = {type: 'spring', duration : 3}
  const [selected, setSelected] =useState(0); 
  const tLength = TestimonialData.length;



  return (
    <div className='Testimonial'>
    <div className='Testimonials'>
    <div className='left-t'>
        <span>Testimonials</span>
        <span className='stroke-text'>What they</span>
        <span>say about us</span>
        <motion.span
          key={selected}
          transition={transition}
          initial={{opacity:0, x:100}}
          animate={{opacity:1,x:0}}
          exit={{opacity:0,x: -100}}
        >
            {TestimonialData[selected].review}
        </motion.span>
        <span>
        <span style={{color:'orange'}}>
            {TestimonialData[selected].name}
        </span>{" "}
        - {TestimonialData[selected].status}
        </span>


    </div>
    <div className='right-t'>
    <motion.div
    initial={{opacity:0, x: -100}}
    transition={{...transition,duration:2}}
    whileInView={{opacity:1,x:0}}
    ></motion.div>
    <motion.div
    initial={{opacity:0, x: 100}}
    transition={{...transition,duration:2}}
    whileInView={{opacity:1,x:0}}>

    </motion.div>
    
      <motion.img 
      key={selected}
      transition={transition}
      initial={{opacity:0, x:100}}
      animate={{opacity:1,x:0}}
      exit={{opacity:0,x: -100}}
      className="imgg" src={TestimonialData[selected].image} alt=''/>
      <div className="arrow">
        <img
         onClick={() => {
          selected === 0
          ? setSelected(tLength - 1)
          : setSelected((prev) => prev - 1)
         }}   
         src={leftarrow} alt=''/>
        <img 
        onClick={() => {
          selected === tLength - 1
          ? setSelected(tLength - 1)
          : setSelected((prev) => prev + 1)
         }}
        src={rightarrow} alt=''/>

      </div>
    </div>

    </div> 
    <Join/>
    <Footer/>
   
    

    </div>
  )
}

export default Testimonials
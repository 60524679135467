import React from 'react'
import Header from '../Header/Header'
import Heart from '../images/Heart.png'
import png1 from '../images/png1.png'
import graph from '../images/graph.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'
import '../Hero/hero.css'
const Hero =() => {
  const transition = {type: 'spring', duration : 3}
  const mobile = window.innerWidth<=768 ? true:false;
  return (
    <div className="" >
    <div className='all '>
    <div className="hero">
      <div className="left-h">
      <Header/>
      {/* the add */}
      <div className='the-best-ad'>
        <motion.div
        initial={{ left:mobile?'165px':'238px'}}
        whileInView={{left:'8px'}}
        transition={{...transition, type:'tween'}}
        ></motion.div>
        <span>the best fitness  club in the town</span>
      </div>
        {/* heading */}
      <div className='hero-text'>
        <div>
          <span className='stroke-text'>Shape </span>
          <span>your</span>
        </div>
        <div>
          <span>ideal body</span>
        </div>
        <div>
        <span>
          In here will we help you to Shape and build your ideal body and live up your life to fullest
        </span>
        </div>
        </div>
        {/*  figures*/}
        <div className='figures'>
        <div>
        <span>
          < NumberCounter end={140} start={10} delay='4' preFix='+' />
        </span>
        <span>expert coachs</span>
        </div>
        <div>
        <span>
        < NumberCounter end={978} start={500} delay='4' preFix='+' />
        </span>
        <span>members joinde</span>
        </div>
        <div>
        <span>< NumberCounter end={50} start={2} delay='4' preFix='+' /> </span>
        <span>Fitness Programs</span>
        </div>
        </div>
      {/* hero buttons */}
      <div className='hero-buttons'>
        <buttons className="btn">Get Started</buttons>
        <buttons className="btn">Lern More</buttons>

      </div>
      </div>
      <div className="right-h">
        <buttons className="btn" >Join now</buttons>
      < motion.div
      initial={{right:"-1rem"}}
      whileInView={{right:"3rem"}}
      transition={transition}
      className='heart-rate'>
        <img className='hear' src={Heart} alt=""/>
        <span>Heart-Rate</span>
        <span><NumberCounter end={50} start={2} delay='4' preFix='BP -'/></span>

      </motion.div>
      {/* Hero-img */}
      <motion.img 
       initial={{right: '11rem'}}
       whileInView={{right:'10rem'}}
       transition={transition}     
      src={png1} alt="" className='hero-image' />
      {/* calories */}
      <motion.div
      initial={{right:"37rem"}}
      whileInView={{right:"28rem"}}
      transition={transition} 
      className='calories'>
      <img src={graph} alt="" className='calories-img' />
        <span>calories</span>
        <span><NumberCounter end={900} start={300} preFix='CAL -'/></span>


      </motion.div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Hero
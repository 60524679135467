import React from 'react'
import '../Plans/Plans.css'
import Pricing from '../Pricing/Pricing'

const Plans =() => {
  return (
      <div className='plans'>
    
      <div className="container">
       <div className='program-header' >
         <span className='stroke-text2'>Ready to start </span>
         <span className='your'>your journey</span>
         <span className='stroke-text2'>now with us</span>
       </div>
      <div className='TextExample'>
      <Pricing/>

      </div>
    </div>
     
    </div>
  )
}

export default Plans
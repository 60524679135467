import React from 'react'
import Hero from './Components/Hero/Hero'
import '../src/App.css'
import Programs from './Components/Programs/Programs'
import Reasons from './Components/Reasons/Reasons'
import Plans from './Components/Plans/Plans'
import Testimonials from './Components/Testimonials/Testimonials'
// import Pricing from './Components/Pricing/Pricing'



function App() {
  return (
    <>
      <Hero/>
      <Programs/>
      <Reasons/>
      <Plans/>
      <Testimonials/>
    
      
    </>
  )
}

export default App
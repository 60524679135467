import React from 'react'
import '../Reasons/Reasons.css'
import why1 from '../images/why1.jpg'
import why2 from '../images/why3.jpg'
import why3 from '../images/why2.jpg'
import why4 from '../images/why4.jpg'
import why5 from '../images/why5.jpg'
import tick from '../images/tick.png'
import nike from '../images/nike1.png'
import newbal from '../images/new1.png'
import adidas from '../images/puma.png'




function Reasons() {
  return (
    <div className='Reason'>
    <div className="Reasons" id='reasons'>
        <div className="left-r">
        <img className='why' src={why1} alt="" />
        <img className='why1' src={why2} alt="" />
        <img className='why2'src={why3} alt="" />
        <img className='why3'src={why4} alt="" />
        <img className='why3'src={why5} alt="" />
        

        </div>
        <div className="right-r">
            <span className='some'>Some reasons </span>
            <div className='us'>
            <span className='stroke-text'>why</span>
            <span className='choos' >  Choose us?</span>
            </div>
             <div className='details-r'>
                <div >
                <img className='tick' src={tick} alt=""/>
                    <span className='tick-text'> Over 140+ Extra Coachs. </span>
                </div>
                <div>
                <img className='tick' src={tick} alt=""/>
                    <span className='tick-text'> train smarter and faster than before.</span>
                </div>
                <div>
                <img className='tick' src={tick} alt=""/>
                    <span className='tick-text'> 1 free program for new members.
                    </span>
                </div>
                <div>
                <img className='tick' src={tick} alt=""/>  
                    <span className='tick-text'>  reliabl partners.</span>
                </div>
             </div>
             <span className='style' style={{
                color:"gray",
                fontWeight:"normal",
             }}>
                our partners
             </span>
             <div className='partners'>
                <img src={newbal} alt=''/>
                <img src={nike} alt=''/>
                <img className='adi' src={adidas} alt=''/>

             </div>
        </div>
    </div>
    </div>
  )
}

export default Reasons
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';
import "../Pricing/Pricing.css"
// import tick from '../images/tick.png'
import tick from '../images/tick.png'



const useStyles = makeStyles((theme) => ({
  section: {
    // backgroundImage: 'url("nereus-assets/img/bg/pattern1.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    
  },
  cardHeader: {
    paddingTop: theme.spacing(3),
  },
}));

export default function Pricing(props) {
  const classes = useStyles();

  const content = {
    // 'badge': 'LOREM IPSUM',
    // 'header-p1': 'Donec lacinia',
    // 'header-p2': 'turpis non sapien lobortis pretium',
    // 'description': 'Integer feugiat massa sapien, vitae tristique metus suscipit nec.', 
    'option1': 'Monthly',
    'option2': 'Annual',
    '01_title': 'Basic Plan',
    '01_price': '$25',
    '01_suffix': ' ',
    '01_benefit1': ' 2 Hour of excercises',
    '01_benefit2': 'Free consultaion to coaches',
    '01_benefit3': 'Access to The Community ',
    // '01_benefit4': '10 GB Storage',
    '01_primary-action': 'Join Now',
    '01_secondary-action': 'Know More',
    '02_title': 'PREMIUM PLAN',
    '02_price': '$30',
    // '02_suffix': ' / mo',
    '02_benefit1': '5 hour of excercises',
    '02_benefit2': 'Free consultaion of Coaches',
    '02_benefit3': 'Accessto minibar',
    // '02_benefit4': '25 GB Storage',
    '02_primary-action': 'Join Now',
    '02_secondary-action': 'Know More',
    '03_title': 'PRO PLAN',
    '03_price': '$45',
    // '03_suffix': ' / mo',
    '03_benefit1': '8 hour of excercisess',
    '03_benefit2': 'Consultation of private Coach',
    '03_benefit3': 'Free Fitness Merchandises',
    // '03_benefit4': '50 GB Storage',
    '03_primary-action': 'Join now',
    '03_secondary-action': 'Learn more',
    ...props.content
  };

  const [state, setState] = React.useState({
    checkbox: true,
  });

  const handleChange = (event) => {
    setState({ ...state, checkbox: event.target.checked });
  };

  return (
    <div className='pricing'>
    <section className={classes.section}>
      <Container maxWidth="lg">
        <Box py={8} textAlign="center">
          <Box mb={3}>
            <Container maxWidth="sm">
              <Typography variant="overline" color="textSecondary">{content['badge']}</Typography>
              <Typography variant="h3" component="h2" gutterBottom={true}>
                <Typography variant="h3" component="span" color="primary">{content['header-p1']} </Typography>
                <Typography variant="h3" component="span">{content['header-p2']}</Typography>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" paragraph={true}>{content['description']}</Typography>

              <div>
                <Typography variant="subtitle1" component="span">{content['option1']}</Typography>
                &nbsp; <Switch name="checkbox" color="primary" checked={state.checkbox} onChange={handleChange} /> &nbsp;
                <Typography variant="subtitle1" component="span">{content['option2']}</Typography>
              </div>
            </Container>
          </Box>
          <Grid container spacing={8}>
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardHeader title={content['01_title']} className={classes.cardHeader}></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content['01_price']}
                      <Typography variant="h6" color="textSecondary" component="span">{content['01_suffix']}</Typography>
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p"><img className='tick' src={tick} alt=""/>{content['01_benefit1']} </Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p"><img className='tick' src={tick} alt=""/>{content['01_benefit2']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p"><img className='tick' src={tick} alt=""/>{content['01_benefit3']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p" paragraph={true}>{content['01_benefit4']}</Typography>
                  </Box>
                  <Button variant="contained" color="primary">{content['02_primary-action']}</Button>
                  <Box mt={2}>
                    <Link href="#" color="primary">{content['03_secondary-action']}</Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardHeader title={content['02_title']} className={classes.cardHeader}></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content['02_price']}
                      <Typography variant="h6" color="textSecondary" component="span">{content['02_suffix']}</Typography>
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p"><img className='tick' src={tick} alt=""/>{content['02_benefit1']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p"><img className='tick' src={tick} alt=""/>{content['02_benefit2']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p"><img className='tick' src={tick} alt=""/>{content['02_benefit3']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p" paragraph={true}>{content['02_benefit4']}</Typography>
                  </Box>
                  <Button variant="contained" color="primary">{content['02_primary-action']}</Button>
                  <Box mt={2}>
                    <Link href="#" color="primary">{content['03_secondary-action']}</Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardHeader title={content['03_title']} className={classes.cardHeader}></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content['03_price']}
                      <Typography variant="h6" color="textSecondary" component="span">{content['03_suffix']}</Typography>
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p"><img className='tick' src={tick} alt=""/>{content['03_benefit1']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p"><img className='tick' src={tick} alt=""/>{content['03_benefit2']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p"><img className='tick' src={tick} alt=""/>{content['03_benefit3']}</Typography>
                    <Typography color="textSecondary" variant="subtitle1" component="p" paragraph={true}>{content['03_benefit4']}</Typography>
                  </Box>
                  <Button variant="contained" color="primary">{content['02_primary-action']}</Button>
                  <Box mt={2}>
                    <Link href="#" color="primary">{content['03_secondary-action']}</Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
    </div>
  );
}
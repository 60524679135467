import React from 'react'
import MediaCard from '../Cards/MediaCard'
import MediaCard2 from '../Cards/Media.Card2'
import MediaCard3 from '../Cards/MediaCard3'


import '../Programs/programs.css'



function Programs() {
  return (
    <div className='' id='programs'>
    <div className='Programs'>

       <div className='Programs-header'>
        <span className='stroke-text'>Explore our</span>
        <span>Programs</span>
        <span className='stroke-text'>to shap 
        you</span>
    
       {/* <hr className='hr'/> */}
       </div>
       <div className='Media-card'>
       <MediaCard/>
       <MediaCard2/>
       <MediaCard3/>
     </div>
       
       </div>
     
    </div>
  )
}

export default Programs
import img1 from "../images/img1.jpg"
import img2 from "../images/img2.jpg"
import img3 from "../images/img3.jpg"

export const TestimonialData = [

    {
    image:img1,
review:
'I have not been disappointed.Excellent personal trainingBy far the best facilityaround',
name: 'MATHEW HENDRICKSON',
status:'ENTERPENEUR'
},
{
    image: img2,
review:
'This is a fantastic gym My first experience with  and have not been disappointed.Excellent personal trainingBy far the best facilityaround',
name: 'JAMESON',
status:'COACH'
},
{
    image: img3,
review:
'Excellent personal trainingBy far the best facilityaround',
name: ' HENDRICKSON',
status:'TRAINER'
}
];
            
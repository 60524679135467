import React from 'react'
import './Footer.css'
import Git from '../images/Git2.png'
import Insta from '../images/Insta.png' 
import Link from '../images/Link.png' 
import logo1 from '../images/logo1.png' 


function Footer() {
  return (
    <div className='Footer-container'>
    <hr/>
    <div className='footer'>
        <img src={Git}  alt="" />
        <img src={Insta}  alt="" />
        <img src={Link}  alt="" />
    </div>
    <div className='logoo'>
        <img src={logo1} alt=""/>
    </div>
    </div>
  )
}

export default Footer
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import '../Cards/card.css';
import back from '../images/card.jpg'



export default function ActionAreaCard() {
  return (
    <div className='card-1'>
    <Card sx={{ maxWidth: 300}}>
      <CardActionArea>
        <img className='media-img2' src={back} alt=""
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Cardio Traning
          </Typography>
          <Typography variant="body2" color="text.secondary">
           In this process. you are trained to do sequential moves in range of 20 until
           30 minutes.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </div>
  );
}

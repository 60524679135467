import React, { useState } from 'react'
import '../Header/header.css'
import logo from '../images/logo1.png'
import nav from '../images/nav.png'
import {Link} from 'react-scroll';
const Header = ()=> {
  const mobile = window.innerWidth<=768 ? true: false;
  const [menuOpened, setMenuOpened] = useState(false);
  return (
      <div className="header">
        <img src={logo} alt="" className='logo '/>
        {menuOpened=== false && mobile===true ?( 
          <div style={{backgroundColor:'black',borderRadius:'5px' ,}}
          onClick={()=>setMenuOpened(true)}
          >
            <img className='navv' src={nav} alt='' style={{width:'3rem'}} />
            </div>

        ): ( <ul className="header-menu">
        <li><Link
         onClick={()=>setMenuOpened(false)}
         to='Header'
         span={true}
         smooth={true}
         >Home</Link></li>
        <li><Link
         onClick={()=>setMenuOpened(false)}
         to='programs'
         span={true}
         smooth={true}
         >Programs</Link></li>
        
        <li><Link
         onClick={()=>setMenuOpened(false)}
         to='Reason'
         span={true}
         smooth={true}
         >why Us</Link></li>
        <li><Link
         onClick={()=>setMenuOpened(false)}
         to='plans'
         span={true}
         smooth={true}
         >Plans</Link></li>
        <li><Link
         onClick={()=>setMenuOpened(false)}
         to='Testimonials'
         span={true}
         smooth={true}
         >Testimonials</Link></li>

    </ul>)}
       
    </div>
  )
}

export default Header